import React from 'react'
import MainLayout from '../layouts/Main'
import '../Components/Page/SecurityProfile/styles.scss'
import { Helmet } from 'react-helmet'

function SecurityProfile() {
  return (
    <MainLayout className="page-home">
    <Helmet>
      <title>Whistic Security Profile - Wunderkind</title>
      <meta 
        name="description" 
        content="Access detailed Whistic content seamlessly on our website. Stay informed about vendor security and risk management with Whistic's powerful tools." 
      />
      <meta 
        name="keywords" 
        content="Whistic, vendor security, risk management, security profiles, Whistic integration, Gatsby, Whistic API" 
      />

      {/* Canonical URL */}
      <link rel="canonical" href="https://www.wunderkind.co/security-profile/" />

      {/* Open Graph Metadata */}
      <meta property="og:title" content="Whistic Integration - Wunderkind Site" />
      <meta 
        property="og:description" 
        content="Explore vendor security and risk management solutions powered by Whistic. Access and integrate Whistic content easily on our website." 
      />
      <meta property="og:url" content="https://www.wunderkind.co/security-profile/" />
      <meta property="og:type" content="website" />
      <meta 
        name="twitter:description" 
        content="Seamlessly access Whistic's vendor security and risk management tools right from our website." 
      />
    </Helmet>
      <main className='sp-main'>
        <div className="container sp-header-desc-container">

        <h2>Whistic Security Profile</h2>
        <p>To help you gain a better understanding of Wunderkind security, we have partnered with Whistic to provide you with more information about all our security including confidential documents such as SOC reports, cyber insurance, penetration testing report and standard questionnaires such as our CSA CAIQ, SIG Lite, and VSA. 
</p>
<p>Below you will find our publicly available Whistic Security Profile which conveniently centralizes publicly available security documents such as CSA CAIQ, SOC 3, Security Whitepaper, and  Information Security FAQs.
</p>
        </div>
        <iframe
          className='sp-iframe'
          src='https://public-profile.whistic.com/4a94a5a5-cf37-4495-8e99-49d3ba3cdcee'
        />
      </main>
    </MainLayout>
  )
}

export default SecurityProfile